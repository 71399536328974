<template>

  <div>
      <div class="searchBox mb-3 flex justify-space-between align-center">
        <input type="text" placeholder="Search Job Title or Keyword" v-model="searchText" id="search" name="searchText" style="padding:1.2rem; line-height:1.4;" />
        <button class="btn btn__dark ml-3 mt-0" style="min-height:44px;" v-show="this.searchText" @click="clearSearch()">Clear</button>
      </div>

      <div class="dashboard__container--body pt-3">
        <Loader v-if="(!sortedGroups || (sortedGroups && sortedGroups.length == 0)) || performingRequest" />
        <div class="dashboard__container--body--col flex" v-for="(group, index) in sortedGroups" :key="group.id">
          <GroupCard :shift="group" :showLink="true" @open="showApplicationModal" />
        </div>
        <transition name="modal">
          <div v-if="showModal && activeShift">
            <JobApplication @close="closeApplicationModal()" :shift="activeShift" />
          </div>
        </transition>
      </div>

      
    </div>

</template>

<style scoped>
  .searchBox {
    width:calc(50% - 1.6rem);
  }
  @media only screen and (max-width: 600px) {
    .searchBox {
      width:100%;
      margin-left:0;
    }
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import JobApplication from '@/components/JobApplication.vue'
import GroupCard from '@/components/Shifts/ShiftCard.vue'

export default {
  props: ['shift'],
  name: 'findGroups',
  data: () => ({
    showModal: false,
    performingRequest: false,
    searchText: '',
    lat: 0,
    lng: 0,
    activeShift: {}
  }),
  computed: {
    ...mapState(['userProfile', 'currentUser', 'groups']),
    isApproved() {
      return ((this.userProfile && this.userProfile.phoneVerified == 'approved'))
    },
    newGroups() {
      if (this.userProfile && this.userProfile.id) {
        return this.groups.filter(group => {
          return ((!group.members || group.members.length == 0) || !group.members.includes(this.userProfile.id))
        })
      } else {
        return this.groups
      }
    },
    filteredGroups() {
      if (!this.searchText) {
        return this.newGroups
      } else {
        return this.newGroups.filter(item => {

          let jobTitles = []

          if (item.job && item.job.length >= 1) {
            item.job.forEach(item => {
              if (item.title) {
                jobTitles.push(item.title)
              }
              
            })
          }
          const computedObj = { ...item,
          description: item.description,
          job: jobTitles,
          // job2: item.job[1].title || null,
          // job3: item.job[2].title || null,
          // job4: item.job[3].title || null,
          // job5: item.job[4].title || null,
          location: item.location,
          title: item.title,
        }
        return Object.keys(computedObj)
          .some(key => ('' + computedObj[key]).toLowerCase().includes(this.searchText.toLowerCase()))
        // return (
        //   item.title.toLowerCase().includes(this.searchText.toLowerCase()) || item.location.toLowerCase().includes(this.searchText.toLowerCase()) || item.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.job.some(job => job.title.toLowerCase().includes(this.searchText.toLowerCase())))
        })
      }

    },
    coordinates() {
      return `${this.lat}, ${this.lng}`;
    },
    sortedGroups() {
      if (this.lat == '0' || this.searchText.length >= 3) {
        return this.filteredGroups
      } else {
        return this.resultsByProximity()
      }
    },
  },
  created() {
    this.locatorButtonPressed()
  },
  mounted() {
    this.resultsByProximity()

  },
  methods: {
    showApplicationModal(shift) {
      console.log(shift)
      this.activeShift = shift
      this.showModal = true
    },
    closeApplicationModal() {
      this.showModal = false
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
         position => {
            this.lat = (position.coords.latitude || 39.1031182);
            this.lng = (position.coords.longitude || -84.5120196);
          },
         error => {
           console.log(error.message);
         },
      )
    },
    resultsByProximity(){
      // this.performingRequest = true
      if (this.lat == 0) {
        return this.locatorButtonPressed()
      }
      console.log(this.lat)
      const asin = Math.asin
      const cos = Math.cos
      const sin = Math.sin
      const PI_180 = Math.PI / 180

      function hav(x) {
        const s = sin(x / 2)
        return s * s
      }

      function relativeHaversineDistance(lat1, lon1, lat2, lon2) {
        const aLatRad = lat1 * PI_180
        const bLatRad = lat2 * PI_180
        const aLngRad = lon1 * PI_180
        const bLngRad = lon2 * PI_180

        const ht = hav(bLatRad - aLatRad) + cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad)
        return asin(ht)
      }
      let sorted

      if (this.lat != 0 ) {
        console.log(this.lat)
        sorted = this.newGroups.sort((a, b) => relativeHaversineDistance(a.center.lat, a.center.lng, this.lat, this.lng) - relativeHaversineDistance(b.center.lat, b.center.lng, this.lat, this.lng))
      } else {
        console.log('else')
        sorted = this.newGroups
      }
      // const sorted = this.newGroups.sort((a, b) => relativeHaversineDistance(a.center.lat, a.center.lng, this.lat, this.lng) - relativeHaversineDistance(b.center.lat, b.center.lng, this.lat, this.lng))
      // this.performingRequest = false
      return sorted
      // this.performingRequest = false
    },
    clearSearch() {
      this.searchText = ''
    }
  },
  components: {
    Loader,
    GroupCard,
    JobApplication
  },
  metaInfo: () => ({
     title: 'Job Search',
    // titleTemplate: '%s | Job Search',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: 'Jumpstart your career. Search hundreds of food service, facilities service, and hospitality jobs online. Get hired and get paid all in one platform.'
      }
    ],
  }),
  // beforeDestroy() {
  //   this.$store.dispatch("clearGroups")
  // }
}
</script>